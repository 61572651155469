import { ChangeEvent, useEffect } from "react";
import { ArrowPathIcon, CheckIcon, ChevronLeftIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { DatePickerWithRange, RadioButton, ServiceProviderDetails, TagItem, TwGlobalFilter } from "@/components/shared";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { useNavigate, useSearchParams } from "react-router-dom";
import { propsAssetsFilterComponent } from "./props";
import { useCompanyData } from "@/hooks";
import { AssetFinancingStatus } from "@/graphql/__generated__/graphql-operations";
import { useTranslation } from "react-i18next";
import { Popover, PopoverTrigger } from "@/components/ui/Popover";
import { PopoverContent } from "@radix-ui/react-popover";
import { isDarkModeVar } from "@/client";

type RadioOption = {
  label: string;
  value: boolean;
}

// Constants
const startDateSuffix = "T00:00:00+00:00";
const endDateSuffix = "T23:59:59+00:00";

const financingFilters: RadioOption[] = [
  { label: "Financed", value: true },
  { label: "Not Financed", value: false },
];

/**
 * AssetsFilterComponent
 *
 * This component provides filtering functionality for assets, including date range and financing status filters.
 *
 * @param {propsAssetsFilterComponent} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * ```tsx
 * <AssetsFilterComponent
 *   onFilterSubmit={handleFilterSubmit}
 *   displayAmount={10}
 *   isServiceProvider={true}
 * />
 * ```
 */
function AssetsFilterComponent(props: propsAssetsFilterComponent): JSX.Element {

  // Props
  const { onFilterSubmit, displayAmount } = props;
  const backgroundColour = isDarkModeVar() === "true" ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";

  // Hooks
  const navigate = useNavigate();
  const company = useCompanyData();
  const { t } = useTranslation(["translation", "common"]);

  // Pagination
  const paginationState: PaginationState["paginatedAssets"] = usePaginationState("paginatedAssets");
  const { filters } = paginationState;

  const isServiceProvider = props.isServiceProvider ?? company?.__typename === "ServiceProvider";

  // URL params
  const [searchParams] = useSearchParams();
  const dateParam = searchParams.get("date");
  const poolID = searchParams.get("poolID");
  const spID = searchParams.get("spID");
  const poolStatus = searchParams.get("poolStatus");

  useEffect(() => {
    if(poolID && spID && poolStatus) {
      updatePaginationState({ paginatedAssets: { filters: { assetfinancingpoolId: poolID } } });
      onFilterSubmit({ first: displayAmount, assetfinancingpoolId: poolID});
    }
  }, []);

  useEffect(() => {
    if (dateParam) {
      // Extract and format date using 'date-fns'
      const formatDate = format(new Date(dateParam), "yyyy-MM-dd");
      const startDate = new Date(formatDate + startDateSuffix);
      const endDate = new Date(formatDate + endDateSuffix);

      updatePaginationState({ paginatedAssets: { filters: { from: startDate, to: endDate } } });
      onFilterSubmit({ first: displayAmount, from: startDate, to: endDate });
    }

    if (poolID) {
      const useExisting = filters.assetfinancingpoolId === poolID;

      // Reset filters when poolID changes
      updatePaginationState({ paginatedAssets: {
        filters: {
          isFinanced: useExisting ? filters.isFinanced : null,
          assetfinancingpoolId: poolID
        }
      }});

      const updatedSubmitParams = {
        first: displayAmount,
        assetfinancingpoolId: poolID,
        isFinanced: useExisting ? filters.isFinanced : null,
      };
      onFilterSubmit(updatedSubmitParams);
    }
  }, [location.search, poolID]);


  /**
   * OnChange handler for RadioButtons
   *
   * @param {string} value - The selected value.
   *
   * @example
   * ```tsx
   * onChangeRadioButton("true");
   * ```
   */
  const onChangeRadioButton = (value: string) => {
    updatePaginationState({ paginatedAssets: { filters: { isFinanced: value === "true" ? true: false } } });
  };

  /**
   * OnChange Handler for Filter input field.
   *
   * @param {ChangeEvent<HTMLInputElement>} event - The change event.
   *
   * @example
   * ```tsx
   * handleFilterOnChange(event);
   * ```
   */
  function handleFilterOnChange(event: ChangeEvent<HTMLInputElement>) {
    updatePaginationState({ paginatedAssets: { filters: { filter: event.target.value } } });
  }

  /**
   * Function to set date range
   *
   * @param {object} newDateRange - The new date range.
   * @param {Date | undefined} newDateRange.from - The start date.
   * @param {Date | undefined} newDateRange.to - The end date.
   *
   * @example
   * ```tsx
   * setDateRange({ from: new Date(), to: new Date() });
   * ```
   */
  function setDateRange(newDateRange: { from: Date | undefined; to: Date | undefined }) {
    updatePaginationState({ paginatedAssets: { filters: { from: newDateRange.from, to: newDateRange.to } } });
  }

  /**
   * OnClick handler for Reset button
   */
  const handleResetClick = () => {
    // Resets the URL for SP only
    if(company?.__typename === "ServiceProvider") {
      navigate("/user/assetoverview");
    }
    // Update state
    updatePaginationState({
      paginatedAssets: {
        filters: {
          from: null,
          to: null,
          filter: "",
          isFinanced: null
        }
      }
    });
    // Call parent function to update query
    onFilterSubmit({
      first: displayAmount,
      from:  null,
      to: null,
      filter: "",
      isFinanced: null,
    });
    // Update state
    setDateRange({ from: undefined, to: undefined });
    toast.success(t("common:text.filterremoved"), {toastId: "assetFilterRemoved"});
  };

  /**
   * OnClick handler for Submit button
   */
  const handleSubmitClick = () => {
    // Format dates to ISO string
    const formatFromDate = filters.from ? new Date(format(new Date(filters.from as Date), "yyyy-MM-dd")+"T00:00:00+00:00") : null;
    const formatToDate = filters.to ? new Date(format(new Date(filters.to as Date), "yyyy-MM-dd")+"T23:59:59+00:00") : null;
    //
    onFilterSubmit({
      first: displayAmount,
      from: formatFromDate,
      to: formatToDate,
      filter: filters.filter,
      isFinanced: filters.isFinanced,
    });
    toast.success(t("common:text.filterapplied"), {toastId: "assetFilterApplied"});
  };

  /**
   * Function to go back to the previous page
   *
   */
  const previousPage = () => {
    // Reset the filters
    updatePaginationState({
      paginatedAssetFinancingRounds: {
        filters: {
          poolId: ""
        }
      }
    });
    updatePaginationState({
      paginatedAssets: {
        filters: {
          from: null,
          to: null,
          filter: "",
          isFinanced: null,
          assetfinancingpoolId: ""
        }
      }
    });
    navigate(-1);
  };

  return (
    <div className="mb-1 -mt-4" data-cy="asset-filter-component">
      {
        isServiceProvider ?
          <TwGlobalFilter
            value={filters.filter ?? ""}
            placeholder={t("translation:assetsoverview.filter")}
            onChange={handleFilterOnChange}
            className="mt-6 mb-4"
          />:
          // {/* SP Details */}
          <div className="mt-2 rounded-md pt-4 justify-start flex flex-row md:flex items-start md:justify-between">
            <div className="w-full md:w-auto flex-grow">
              {
                spID !== "" &&
                  <ServiceProviderDetails
                    financingStatus={poolStatus as AssetFinancingStatus ?? ""}
                    serviceProviderId={spID as string}
                  />
              }
            </div>
            <div className="flex w-full justify-end md:w-auto">
              <button
                className="mf-btn-action-small-primary-outline px-1 md:px-[12px]"
                onClick={previousPage}
              >
                <span className="hidden md:inline">
                  {t("common:buttons.back")}
                </span>
                <ChevronLeftIcon className="w-5 h-5 md:hidden" />
              </button>
            </div>
          </div>
      }
      <div className={`${!isServiceProvider ? "mt-2": ""} flex flex-wrap items-center justify-between`}>
        {/* Date and Financing Status Filters */}
        <div className="flex max-md:flex-wrap md:justify-between items-center gap-2 mb-1 w-full">
          <div className="flex flex-wrap gap-2 md:w-full">
            {/* Left container --> DatePicker */}
            <div className="flex-grow max-w-[220px]">
              <DatePickerWithRange
                dateRange={{
                  from: filters.from === null ? undefined : filters.from,
                  to: filters.to === null ? undefined : filters.to,
                }}
                setDateRange={setDateRange}
              />
            </div>
            {/* Right container --> Filter box */}
            <Popover>
              <PopoverTrigger
                className="border-[1px] border-gray-200 rounded-md flex item-center h-[40px]
                      md:w-[220px] px-2 py-1 dark:border-white relative select-none"
              >
                <div className="sm:mr-2 h-full items-center flex shrink-0">
                  <FunnelIcon className="w-5 h-5 text-gray-400 dark:text-white" />
                  {
                    typeof filters.isFinanced === "boolean" ?
                      <div className="hidden sm:flex items-center space-x-2 select-none mt-[2px]">
                        <TagItem label={filters.isFinanced ? "Financed": "Not Financed"} />
                      </div>
                      :
                      <p className="hidden sm:block text-sm text-gray-400 pl-2">
                        {t("portfoliopage.filter.selectstatus")}
                      </p>
                  }
                </div>
              </PopoverTrigger>
              <PopoverContent
                className={`shadow-lg h-auto rounded-md p-4 font-Montserrat z-[200] md:min-w-[200px] mt-1 ${backgroundColour}`}
              >
                {/* Status filters */}
                {financingFilters.map(option => (
                  <RadioButton
                    key={option.label}
                    option={option}
                    checked={filters.isFinanced === option.value}
                    onChange={(value) => onChangeRadioButton(value.toString())}
                  />
                ))}
              </PopoverContent>
            </Popover>
          </div>
          {/* Right container --> Action buttons */}
          <div className="flex justify-end gap-2 select-none">
            {/* Reset button */}
            <button
              onClick={handleResetClick}
              className="mf-btn-action-small-primary-outline min-w-fit md:min-w-[100px] px-2"
            >
              <div><ArrowPathIcon className="w-5 h-5 md:hidden" /></div>
              <div className="hidden md:block">
                {t("common:buttons.reset")}
              </div>
            </button>

            {/* Submit button */}
            <button
              onClick={handleSubmitClick}
              className="mf-btn-action-small-primary-filled min-w-fit md:min-w-[100px] px-2"
            >
              <div><CheckIcon className="w-5 h-5 md:hidden" /></div>
              <div className="hidden md:block">
                {t("common:buttons.submit")}
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AssetsFilterComponent;
