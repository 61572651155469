import { AssetFinancingRound } from "@/graphql/__generated__/graphql-operations";
import { formatDateLocale, formatNumberLocale } from "@/utils/helpers";
import { ServiceProviderDetails } from "@/components/shared";
import { useIntl } from "react-intl";
import { useTranslation } from "react-i18next";
import { ValueSkeleton } from "@/components/skeletons";

type Props = {
  assetfinancingrounds: AssetFinancingRound[];
  loading: boolean;
}

/**
 * RecentActivity component displays a list of recent asset financing rounds,
 * including service provider details, dates, and financial data such as inventory
 * bought and sold, and amounts financed or not financed.
 *
 * @component
 * @example
 * ```tsx
 * import { AssetFinancingRound, useGetPaginatedAssetFinancingRoundsQuery } from "@/graphql/__generated__/graphql-operations";
 *
 * const { data, loading } = useGetPaginatedAssetFinancingRoundsQuery({
 *   variables: {
 *     first: 5,
 *     after: null,
 *     poolId: null
 *   },
 *   fetchPolicy: "cache-first"
 * });
 *
 * return (
 *  <RecentActivity
 *    assetfinancingrounds={data?.paginatedAssetFinancingRounds.edges.map((edge) => edge.node) as AssetFinancingRound[] ?? []}
 *    loading={loading}
 *  />
 * )
 * ```
 *
 * @param {Props} props - The props for the RecentActivity component.
 * @param {AssetFinancingRound[]} props.assetfinancingrounds - Array of asset financing rounds.
 * @param {boolean} props.loading - Indicates if the data is still loading.
 * @returns {JSX.Element} The RecentActivity component.
 */
function RecentActivity({ assetfinancingrounds, loading }: Props): JSX.Element {

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation();

  // Helper Functions
  /**
   * Renders the inventory details for an asset financing round.
   *
   * @param {AssetFinancingRound} afr - The asset financing round object.
   * @returns {JSX.Element} The JSX for inventory details.
   */
  const renderInventoryDetails = (afr: AssetFinancingRound): JSX.Element => (
    <>
      <div className="items-center min-w-[140px]">
        <p className="text-sm dark:text-gray-400">
          {t("dashboard.serviceprovider.inventoryvalue")}
        </p>
        <p className="flex items-center text-sm font-thin text-green-500">
          {formatNumberLocale(intl, afr.amountFinanced ?? 0, "currency")}
        </p>
      </div>
      <div className="items-center min-w-[140px]">
        <p className="text-sm dark:text-gray-400">
          {t("dashboard.recenttransactions.inventorybought")}
        </p>
        <p className="flex items-center text-sm font-thin text-green-500">
          {formatNumberLocale(intl, afr.inventoryBought ?? 0, "currency")}
        </p>
      </div>
      <div className="items-center min-w-[140px]">
        <p className="text-sm dark:text-gray-400">
          {t("dashboard.recenttransactions.inventorysold")}
        </p>
        <p className="flex items-center text-sm font-thin text-red-500">
          {formatNumberLocale(intl, afr.inventorySold ?? 0, "currency")}
        </p>
      </div>
    </>
  );

  /**
   * Renders the receivable details for an asset financing round.
   *
   * @param {AssetFinancingRound} afr - The asset financing round object.
   * @returns {JSX.Element} The JSX for receivable details.
   */
  const renderReceivableDetails = (afr: AssetFinancingRound): JSX.Element => (
    <>
      <div data-cy="amountFinancedRecentActivity" className="dark:text-gray-400 text-gray-600 items-center justify-between min-w-[140px]">
        <p className="text-sm">
          {t("dashboard.recenttransactions.amountfinanced")}
        </p>
        <p className="flex items-center text-sm font-thin text-green-500">
          {formatNumberLocale(intl, afr.amountFinanced ?? 0, "currency")}
        </p>
      </div>
      <div data-cy="amountNotFinancedRecentActivity" className="dark:text-gray-400 text-gray-600 items-center justify-between min-w-[140px]">
        <p className="text-sm">
          {t("dashboard.recenttransactions.amountnotfinanced")}
        </p>
        <p className="flex items-center text-sm font-thin text-red-500">
          {formatNumberLocale(intl, afr.amountNotFinanced ?? 0, "currency")}
        </p>
      </div>
    </>
  );

  return (
    <div
      className="bg-white dark:bg-mfdarklight rounded-md dark:text-white px-2 pt-4
                  pb-2 w-full select-none shadow-lg"
    >
      {/* Top container */}
      <div className="flex items-center justify-between">
        <p className="text-lg font-semibold px-2 -mb-2">
          {t("dashboard.recenttransactions.transactionstitle")}
        </p>
      </div>
      {/* Main content container */}
      {assetfinancingrounds.length === 0 && !loading && (
        <div className="flex flex-col items-center justify-center h-52">
          <p className="text-base font-thin mb-2 px-2">
            {t("dashboard.recenttransactions.placeholderSP")}
          </p>
        </div>
      )}
      <div className="mt-4 flex flex-col gap-y-2 overflow-y-auto md:max-h-[250px] lg:max-h-[300px] overflow-x-clip">
        {/* //*** Not using the optional operator because the skeletons would not go away when loading = false */}
        {loading && (
          Array.from({ length: 5 }, (_, index) => index).map((item) => (
            <ValueSkeleton key={`recent-activity-skeleton-${item}`} className="min-h-[100px]" />
          ))
        )}
        {assetfinancingrounds.length > 0 && !loading && (
          assetfinancingrounds.map((afr) => (
            <div
              className={`flex flex-col hover:border-mforange rounded-lg overflow-visible
                          transition-all duration-300 ease-linear py-1 px-2 cursor-pointer group
                          odd:bg-gray-100 dark:odd:bg-mfdarkbase border-2 border-transparent`}
              key={`recent-${afr._id}`}
            >
              <div className="flex flex-row w-full">
                <div className="flex flex-col xl:flex-row items-center w-full p-2 gap-2">
                  <div className="flex gap-2 flex-col md:flex-row items-end md:items-center w-full md:mr-4 justify-between">
                    {/* SP Name */}
                    <div className="justify-start flex max-lg:w-full">
                      <ServiceProviderDetails
                        serviceProviderId={afr.spdetails?._id}
                      />
                    </div>
                    {/* Date */}
                    <div className="max-md:hidden">
                      <p className="text-sm text-gray-500 dark:text-gray-200 font-thin">
                        {formatDateLocale(intl, afr.createdAt)}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-2 max-md:flex-wrap w-full mt-2 md:mt-0">
                    {afr.inventoryBought !== null && afr.inventorySold !== null
                      ? renderInventoryDetails(afr)
                      : renderReceivableDetails(afr)}
                  </div>
                  <div className="md:hidden w-full text-end">
                    <p className="text-sm text-gray-500 dark:text-gray-200 font-thin">
                      {formatDateLocale(intl, afr.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default RecentActivity;
