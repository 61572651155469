import "@/styles/index.css";
import { StrictMode } from "react";
import {
  Login,
  NotFound,
  Dashboard,
  Settings,
  Investment,
  AssetOverview,
  Admin,
  Home,
  ManageUsers,
  CreateCompany,
  DeploySmartContracts,
  Customers,
  CreditLimitRequest,
  Transactions,
  MakePayment,
  Portfolio,
  PoolActivity,
  DeclareDescent,
  ForgotPassword,
  SetPassword,
  TokenLogin
} from "@/pages";
import {
  Route,
  Routes,
  BrowserRouter
} from "react-router-dom";
import { ProtectedRoute } from "@/routing";
import { client, isDarkModeVar } from "@/client";
import { ApolloProvider } from "@apollo/client";
import { I18nextProvider } from "react-i18next";
import { UserRole, UserType } from "./graphql/__generated__/graphql-operations";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkEnvVars } from "@/utils/envvarchecker";
import { requiredEnvVars } from "@/constants/requiredenvvars";
import { IntlProvider } from "react-intl";
import { Web3ContextProvider } from "./context/Web3Context";
import { SessionProvider } from "@/context/SessionProvider";
import  i18n from "../i18n";
import useLocale from "./hooks/useLocale";
import { ThemeProvider } from "./context";

// Check for required environment variables
checkEnvVars(import.meta.env, requiredEnvVars);

const App = () => {

  // Hooks
  const currentLocale = useLocale();

  return (
    <StrictMode>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        transition={Slide}
        theme={isDarkModeVar() === "true" ? "dark":"light"}
      />
      <ApolloProvider client={client}>
        <Web3ContextProvider>
          <IntlProvider locale={currentLocale}>
            <I18nextProvider i18n={i18n}>
              <BrowserRouter>
                <SessionProvider>
                  <ThemeProvider>
                    <Routes>
                      {/* Path for Developers */}
                      <Route path="admin/" element={<ProtectedRoute roles={[UserRole.User, UserRole.Admin]} usertypes={[UserType.Developer]} />}>
                        <Route path="dashboard" element={<Admin />} />
                      </Route>
                      <Route path="admin/" element={<ProtectedRoute roles={[UserRole.Admin]} usertypes={[UserType.Developer]} />}>
                        <Route path="creditlimitrequests" element={<CreditLimitRequest />} />
                        <Route path="manageusers" element={<ManageUsers />} />
                        <Route path="createcompany" element={<CreateCompany />} />
                        <Route path="deploy" element={<DeploySmartContracts />} />
                        <Route path="transactions" element={<Transactions />} />
                      </Route>
                      {/* Path for Employees and Financiers*/}
                      <Route path="user/" element={<ProtectedRoute roles={[UserRole.User, UserRole.Superuser]} usertypes={[UserType.Employee, UserType.Financier]} />}>
                        <Route path="dashboard" element={<Dashboard />} />
                        <Route path="assetoverview" element={<AssetOverview />} />
                      </Route>
                      <Route path="user/" element={<ProtectedRoute roles={[UserRole.User, UserRole.Superuser, UserRole.Admin]} usertypes={[UserType.Financier]} />}>
                        <Route path="investment" element={<Investment />} />
                        <Route path="portfolio" element={<Portfolio />} />
                        <Route path="poolactivity" element={<PoolActivity />} />
                      </Route>
                      <Route path="user/" element={<ProtectedRoute roles={[UserRole.Superuser]} usertypes={[UserType.Financier, UserType.Employee]} />}>
                        <Route path="manageusers" element={<ManageUsers />} />
                        <Route path="transactions" element={<Transactions />} />
                      </Route>
                      <Route path="user/" element={<ProtectedRoute roles={[UserRole.User, UserRole.Superuser, UserRole.Admin]} usertypes={[UserType.Financier, UserType.Employee, UserType.Developer]} />}>
                        <Route path="settings" element={<Settings />} />
                      </Route>
                      {/* SP Only */}
                      <Route path="user/" element={<ProtectedRoute roles={[UserRole.User, UserRole.Superuser]} usertypes={[UserType.Employee]} />}>
                        <Route path="customersoverview" element={<Customers />} />
                        <Route path="makepayment" element={<MakePayment />} />
                        <Route path="creditlimitrequests" element={<CreditLimitRequest />} />
                      </Route>
                      <Route path="*" element={<NotFound />} />
                      <Route path="/" element={<Home />} />
                      <Route path="/verify-token/:token" element={<TokenLogin />} />
                      <Route path="login" element={<Login />} />
                      <Route path="declaredescent" element={<DeclareDescent />} />
                      <Route path="forgotpassword" element={<ForgotPassword />} />
                      <Route path="setpassword" element={<SetPassword />} />
                    </Routes>
                  </ThemeProvider>
                </SessionProvider>
              </BrowserRouter>
            </I18nextProvider>
          </IntlProvider>
        </Web3ContextProvider>
      </ApolloProvider>
    </StrictMode>
  );
};

export default App;
