import { updatePaginationState } from "@/cache/appstate/WriteQueries";
import { RadioButton, TagItem, TwGlobalFilter } from "@/components/shared";
import { AssetFinancingStatus, GetPaginatedInvestmentsQueryVariables } from "@/graphql/__generated__/graphql-operations";
import { usePaginationState } from "@/hooks/cache/appState/usePaginationState";
import { PaginationState } from "@/types";
import { ArrowPathIcon, CheckIcon, FunnelIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { ChangeEvent } from "react";
import { toast } from "react-toastify";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/Popover";
import { isDarkModeVar } from "@/client";

export type propsPoolsFilterComponent = {
  onFilterSubmit: (_variables: GetPaginatedInvestmentsQueryVariables) => void;
  displayAmount: number;
}

type RadioOption = {
  label: string;
  value: AssetFinancingStatus;
}

// Constants
const afpStatusOptions: RadioOption[] = [
  { label: "Open", value: AssetFinancingStatus.Open },
  { label: "Closed", value: AssetFinancingStatus.Closed },
  { label: "Active", value: AssetFinancingStatus.Active },
];

/**
 * PoolsFilter component for filtering investment pools.
 *
 * @param {propsPoolsFilterComponent} props - The properties for the component.
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * ```tsx
 *
 * return (
 *   <PoolsFilter onFilterSubmit={handleFilterSubmit} displayAmount={10} />
 * )
 * ```
 */
const PoolsFilter = (props: propsPoolsFilterComponent): JSX.Element => {

  // Props
  const { onFilterSubmit, displayAmount } = props;

  const backgroundColour = isDarkModeVar() === "true" ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";

  // Hooks
  const { t } = useTranslation();
  const paginationState: PaginationState["paginatedInvestments"] = usePaginationState("paginatedInvestments");
  const { filters: { companyName, status } } = paginationState;

  /**
   * OnChange Handler for Filter input field.
   * @param {ChangeEvent<HTMLInputElement>} event - The change event.
   */
  function handleFilterOnChange(event: ChangeEvent<HTMLInputElement>) {
    updatePaginationState({ paginatedInvestments: { filters: { companyName: event.target.value } } });
  }

  /**
   * OnChange Handler for Radio buttons.
   * @param {string} key - The key of the filter.
   * @param {string} value - The value of the filter.
   */
  const onChangeRadioButton = (key: string, value: string) => {
    if (key === "status") {
      updatePaginationState({ paginatedInvestments: { filters: { status: value as AssetFinancingStatus }}});
    }
  };

  /**
   * OnClick Handler for Reset button.
   */
  function handleResetClick() {
    // Update cache
    updatePaginationState({ paginatedInvestments: { filters: { companyName: "", status: null }}});
    // Call the filter submit function
    onFilterSubmit({ first: displayAmount as number, companyName: "", status: null });
    toast.success(t("common:text.filterremoved"), {toastId: "investmentFilterRemoved"});
  }

  /**
   * OnClick Handler for Submit button.
   */
  function handleSubmitClick() {
    // Call the filter submit function
    onFilterSubmit({ first: displayAmount, companyName, status });
    toast.success(t("common:text.filterapplied"), {toastId: "investmentFilterApplied"});
  }

  return (
    <div className="mb-1 bg-white px-4 py-3 rounded-md shadow-lg dark:bg-mfdarklight">
      <div className="flex items-center w-full space-x-2">
        <TwGlobalFilter
          value={companyName ?? ""}
          placeholder={t("portfoliopage.filter.title")}
          onChange={handleFilterOnChange}
          className="flex-grow"
        />

        {/* Filter box */}
        <Popover>
          <PopoverTrigger
            data-cy="pools-filter-popover-trigger"
            className="border-[1px] border-gray-200 rounded-md flex item-center h-[40px]
                        md:w-[200px] px-2 py-1 dark:border-white relative select-none"
          >
            <div className="sm:mr-2 h-full items-center flex shrink-0">
              <FunnelIcon className="w-5 h-5 text-gray-400 dark:text-white" />
              {
                status === null &&
              <p className="hidden sm:block text-sm text-gray-400 pl-2">
                {t("portfoliopage.filter.selectstatus")}
              </p>
              }
            </div>
            <div className="hidden sm:flex items-center space-x-2 select-none mt-[2px]">
              {
                status !== null &&
              <TagItem label={status as string} />
              }
            </div>
          </PopoverTrigger>
          {/* Content */}
          <PopoverContent
            data-cy="pools-filter-popover-content"
            align="start"
            className={`mf-filter-container ${backgroundColour}`}
          >
            {/* Status filters */}
            {afpStatusOptions.map(option => (
              <RadioButton
                key={option.value}
                option={option}
                checked={status === option.value}
                onChange={(value) => onChangeRadioButton("status", value as string)}
              />
            ))}
          </PopoverContent>
        </Popover>

        {/* Action buttons */}
        <div className="flex justify-end items-center gap-x-2">
          {/* Reset button */}
          <button
            onClick={handleResetClick}
            className="mf-btn-action-small-primary-outline min-w-fit md:min-w-[100px] px-2"
          >
            <div><ArrowPathIcon data-cy="pools-filter-reset-icon" className="w-5 h-5 md:hidden" /></div>
            <div className="hidden md:block">
              {t("common:buttons.reset")}
            </div>
          </button>

          {/* Submit button */}
          <button
            onClick={handleSubmitClick}
            className="mf-btn-action-small-primary-filled min-w-fit md:min-w-[100px] px-2"
          >
            <div><CheckIcon data-cy="pools-filter-submit-icon" className="w-5 h-5 md:hidden" /></div>
            <div className="hidden md:block">
              {t("common:buttons.submit")}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PoolsFilter;
