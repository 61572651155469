import { WithdrawalType } from "@/graphql/__generated__/graphql-operations";
import { GroupedWithdrawals } from "@/types";
import { TxResult, WithdrawalCardModals } from "@/constants/enums";
import { claimInterest, withdraw } from "@/blockchain";
import { useCompanyData, useFinancierData } from "@/hooks";
import { BlankModal, BlankModalContent, BlankModalFooter } from "@/components/modals/BlankModal";
import { ServiceProviderDetails } from "@/components/shared";
import { formatNumberLocale } from "@/utils/helpers";
import { toast } from "react-toastify";
import { useExecuteWithdrawalOnInvestmentMutationHook } from "@/hooks/mutations";
import { useIntl } from "react-intl";
import { propsWithdrawalSummaryModal } from "../props";
import { useTranslation } from "react-i18next";

function WithdrawalSummaryModal(props: propsWithdrawalSummaryModal) {
  // Props
  const {
    setResultModalType,
    activeGroupedWithdrawals,
    setShowModal,
    setTransactionHash,
    activeTotalWithdrawalAmount,
  } = props;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["modals", "components", "common", "toasts"]);
  const companyData = useCompanyData();
  const financierData = useFinancierData();

  const { executeWithdrawalOnInvestmentMutation } = useExecuteWithdrawalOnInvestmentMutationHook({ withdrawal: activeGroupedWithdrawals });

  // Function to handle smart contract withdrawal
  async function handleSmartContractWithdrawal(transaction: GroupedWithdrawals | null) {
    if (!financierData || !companyData || !transaction) return;
    try {
      setShowModal(WithdrawalCardModals.Loading);

      const investments = financierData.investments;
      // Find the investment with the matching ID
      const matchingInvestment = investments.find((investment: { _id: string; }) => investment._id === transaction.investmentID);
      if(!matchingInvestment) return;

      let success = false;
      let transactionHash = "";

      if(transaction.type === WithdrawalType.Investment) {
        [success, transactionHash] = await withdraw(companyData.publickey, matchingInvestment.assetfinancingpool.pooladdress as string, matchingInvestment.amount);
      } else {
        [success, transactionHash] = await claimInterest(companyData.publickey, matchingInvestment.assetfinancingpool.pooladdress as string);
      }

      setTransactionHash(transactionHash);

      if(!success) {
        throw new Error("Transaction unsuccessful");
      }

      await executeWithdrawalOnInvestmentMutation({
        variables: {
          id: transaction.investmentID,
          withdrawalType: transaction.type
        }
      }).catch((err) => console.log(err));

      setResultModalType(TxResult.SUCCESS);
      return;
    } catch (_err) {
      setResultModalType(TxResult.ERROR);
      toast.dismiss();
      toast.error(t("toasts:errorOccurred"), {toastId: "errorOccurred"});
    } finally {
      setShowModal(WithdrawalCardModals.WithdrawalResult);
    }
  }

  return (
    <BlankModal
      setShowModal={() => { setShowModal(WithdrawalCardModals.NoModal); }}
      className="px-6"
      customContentCSS="mf-modal-content"
      title={t("withdrawalsummary.title")}
      titleCSS="text-lg dark:text-white"
    >
      <BlankModalContent>
        {/* Subtext */}
        <div data-cy="withdrawalsummary-subtext" className="mb-4">
          <p className="dark:text-white">
            {t("withdrawalsummary.textbefore")}
            <span className="pl-1 font-semibold">
              {formatNumberLocale(intl, activeTotalWithdrawalAmount , "currency")}
            </span> {t("withdrawalsummary.textafter")}:
          </p>
        </div>

        {/* SP Details */}
        <ServiceProviderDetails
          serviceProviderId={activeGroupedWithdrawals?.spid ?? ""}
        />

        {/* Disclaimer */}
        <div data-cy="withdrawalsummary-disclaimer" className="rounded-md bg-mfpurple p-4 mt-4 mb-8 text-sm">
          <p className="text-white font-semibold">
            {t("withdrawalsummary.note.title")}:
          </p>
          <p className="text-white">
            {t("withdrawalsummary.note.text")}
          </p>
        </div>
      </BlankModalContent>
      <BlankModalFooter>
        {/* Buttons */}
        <div data-cy="withdrawalsummary-buttons" className="flex items-center justify-between">
          <button
            onClick={() => setShowModal(WithdrawalCardModals.MakeWithdrawal)}
            className="mf-btn-action-small-primary-outline"
          >
            {t("common:buttons.back")}
          </button>
          <button
            onClick={() => handleSmartContractWithdrawal(activeGroupedWithdrawals)}
            className="mf-btn-action-small-primary-filled"
          >
            {t("common:buttons.submit")}
          </button>
        </div>
      </BlankModalFooter>
    </BlankModal>
  );
}

export default WithdrawalSummaryModal;
