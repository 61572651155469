import { CreditLimitRequest, CreditLimitRequestCreatedDocument, CreditLimitRequestCreatedSubscription, CreditLimitRequestEdge, CreditLimitRequestStatus, GetPaginatedCreditLimitRequestsQuery, GetPaginatedCreditLimitRequestsQueryVariables, useCreditLimitRequestCreatedSubscription, useGetPaginatedCreditLimitRequestsQuery } from "@/graphql/__generated__/graphql-operations";
import { useEffect, useRef, useState } from "react";
import { AdminPageState, InputItem, SmartContractDataItem } from "@/types/admin";
import VisibilityBarToggle from "./VisibilityBarToggle";
import { formatNumberLocale } from "@/utils/helpers";
import { Pagination } from "@/components/shared";
import { TwInput } from "@/components/shared";
import { CreditLimitAdmin } from "./modals";
import { TwCard } from "@/components/shared";
import { FinanceCard } from "../Dashboard";
import { useIntl } from "react-intl";
import { addAuthorizedUser, authorizationStatusOf, balanceOf, removeAuthorizedUser } from "@/blockchain/tokens/EuroMyron";
import { useBlockchainData } from "@/hooks/cache/useBlockchainData";
import { updateAdminState, updatePaginationState } from "@/cache/appstate/WriteQueries";
import { useAdminPageState } from "@/hooks/cache/appState";
import { AdminPageModals } from "@/constants/enums";
import { TwTableCardList, DataTable } from "@/components/shared/table";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { useTranslation } from "react-i18next";
import { getColumns } from "@/pages/CreditLimitRequests/columns";
import { getColumnHeaderValues } from "@/components/shared/table/utils";
import { ActionButton } from "@/components/shared/table/Table";

interface PaginationRef {
  triggerHandleFilterSubmit: (_newVariables: Partial<GetPaginatedCreditLimitRequestsQueryVariables>) => void;
}

function Admin() {
  const blockchainData = useBlockchainData();
  // Global variable which will hold the subscribeToMore function
  let G_subscribeToMore;

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["translation", "tables"]);
  const state: AdminPageState = useAdminPageState();
  const mdBreakpoint = useMdBreakpoint();

  // States
  const [showSmartContractFunctions, setShowSmartContractFunctions] = useState(false);
  const [currentRequest, setCurrentRequest] = useState<CreditLimitRequest>();
  const [showCLRTable, setShowCLRTable] = useState(false);
  const [showModal, setShowModal]  = useState<AdminPageModals>(AdminPageModals.NoModal);

  const smartContractsData: SmartContractDataItem[] = [
    { title: "balanceOf",inputFields: [{ name: "balanceOf:address", type: "publickey", onChange: handleOnChange, styling: "mf-input-field", placeholder: t("admin.inputfields.publickey.placeholder"), errorMessage: t("admin.inputfields.publickey.errormsg")}], button: { styling: "mf-btn-action-small-primary-filled", text: t("admin.buttons.query"), onClick: () => {bc_balanceOf(state?.balanceOf?.address);} }, output: state?.balanceOf?.output ?? ""},
    { title: "addAuthorizedUser", inputFields: [ { name: "addAuthorizedUser:address", type: "publickey", onChange: handleOnChange, styling: "mf-input-field", placeholder: t("admin.inputfields.publickey.placeholder"), errorMessage: t("admin.inputfields.publickey.errormsg") } ], button: { styling: "mf-btn-action-small-primary-filled", text: t("admin.buttons.query"), onClick: () => {bc_addAuthorizedUser(state?.addAuthorizedUser?.address);} }, output: state?.addAuthorizedUser?.output ?? ""},
    { title: "removeAuthorizedUser", inputFields: [ { name: "removeAuthorizedUser:address", type: "publickey", onChange: handleOnChange, styling: "mf-input-field", placeholder: t("admin.inputfields.publickey.placeholder"), errorMessage: t("admin.inputfields.publickey.errormsg") } ], button: { styling: "mf-btn-action-small-primary-filled", text: t("admin.buttons.query"), onClick: () => {bc_removeAuthorizedUser(state?.removeAuthorizedUser?.address);} }, output: state?.removeAuthorizedUser?.output ?? ""},
    { title: "authorizationStatusOf", inputFields: [ { name: "authorizationStatusOf:address", type: "publickey", onChange: handleOnChange, styling: "mf-input-field", placeholder: t("admin.inputfields.publickey.placeholder"), errorMessage: t("admin.inputfields.publickey.errormsg") } ], button: { styling: "mf-btn-action-small-primary-filled", text: t("admin.buttons.query"), onClick: () => {bc_authorizationStatusOf(state?.authorizationStatusOf?.address);} }, output: state?.authorizationStatusOf?.output ?? ""},
  ];
  // Pagination config
  const paginationRef = useRef<PaginationRef | null>(null);
  const paginationName = "paginatedCreditLimitRequests";

  // Subscription hook
  const { data: subscriptionData, loading: subscriptionLoading } = useCreditLimitRequestCreatedSubscription({});

  /**
   * Get the balance of a public key address
   * @param {string} address - public key address
   */
  async function bc_balanceOf(address: string) {
    const _balance = await balanceOf(address);
    const balance = formatNumberLocale(intl, _balance, "currency");
    updateAdminState({ balanceOf: { output: balance} });
  }

  /**
   * Add an authorized user using their public key address
   * @param {string} address - public key address
   */
  async function bc_addAuthorizedUser(address: string) {
    const addedUser = await addAuthorizedUser(address);
    if(!addedUser) {
      updateAdminState({ addAuthorizedUser:{output:t("admin.output.error").toString()}});
    } else {
      updateAdminState({ addAuthorizedUser:{output:"User authorized!"}});
    }
  }

  /**
   * Remove an authorized user using their public key address
   * @param {string} address - public key address
   */
  async function bc_removeAuthorizedUser(address: string) {
    const removed = await removeAuthorizedUser(address);
    let updateString = "";
    if(!removed) {
      updateString = t("admin.output.error").toString();
    } else {
      updateString = "Authorized user removed!";
    }
    updateAdminState({ removeAuthorizedUser: { output: updateString }});
  }

  /**
   * Check the authorization status of a public key address
   * @param {string} address
   */
  async function bc_authorizationStatusOf(address: string) { //AFP address
    const authorized = await authorizationStatusOf(address);
    const status = authorized ?
      t("admin.output.authorizationStatusOf.success").toString() :
      t("admin.output.authorizationStatusOf.fail").toString();
    updateAdminState({ authorizationStatusOf:{output:status}});
  }

  /**
   * Handler function for filter submit for pagination
   * @param {GetPaginatedCreditLimitRequestsQueryVariables} variables
   */
  const onFilterSubmit = (variables: GetPaginatedCreditLimitRequestsQueryVariables) => {
    // When the filter is submitted in the PageComponent,
    // this will call the handleFilterSubmit inside Pagination.
    paginationRef.current?.triggerHandleFilterSubmit(variables);
  };

  useEffect(() => {
    // Set status to PENDING
    updatePaginationState({ paginatedCreditLimitRequests: { filters: { status: CreditLimitRequestStatus.Pending } } });
    onFilterSubmit({first: 10, status: CreditLimitRequestStatus.Pending});
  }, [showCLRTable]);

  /**
  * onChange handler for input fields
  * @param {object} event - input event
  */
  function handleOnChange(event: { name: string, value: string, isValid: boolean }) {
    // get the updated user input
    const { name, value, isValid } = event;

    if(isValid) {
      const namesSplit = name.split(":");
      updateAdminState({ [namesSplit[0]]:{[namesSplit[1]]:value.toString()}});
    }
  }

  /**
   * Handler for opening the Credit Limit Request modal
   * @param {CreditLimitRequest} creditLimitReq
   */
  function handleModalOpen(creditLimitReq: CreditLimitRequest) {
    updateAdminState({
      creditLimitRequestInput: {
        status: creditLimitReq.status as CreditLimitRequestStatus,
        requestedAmount: creditLimitReq.requestedAmount,
        reasonForRequest: creditLimitReq.reasonForRequest,
        reasonForDenial: creditLimitReq.reasonForDenial as string,
      }});
    setCurrentRequest(creditLimitReq);
    setShowModal(AdminPageModals.CLRDetails);
  }

  useEffect(() => {
    // Subscribe to updates of 'paginatedCreditLimitRequests'
    if(G_subscribeToMore !== undefined || null){
      const subscribe = G_subscribeToMore({
        document: CreditLimitRequestCreatedDocument,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;

          const newRequest = (subscriptionData.data as CreditLimitRequestCreatedSubscription).creditLimitRequestCreated;
          return {
            paginatedCreditLimitRequests: {
              ...prev.paginatedCreditLimitRequests,
              edges: [
                {
                  __typename: "CreditLimitRequestEdge",
                  node: newRequest as CreditLimitRequest,
                  cursor: newRequest!.createdAt,
                },
                ...prev.paginatedCreditLimitRequests.edges,
              ],
            },
          };
        },
      });

      // Cleanup subscription on component unmount
      return () => subscribe();
    }
  }, [G_subscribeToMore, subscriptionLoading, subscriptionData]);

  /**
   * Get action buttons for TwTableCardList
   * @param {number} index
   * @param {CreditLimitRequest} dataObj
   * @returns {JSX.Element[]}
   */
  const getActionButtons = (index: number, dataObj: CreditLimitRequest): JSX.Element[] => {
    return [
      <ActionButton key={"clr-admin-action-btn-"+index} dataObj={dataObj} fn={handleModalOpen} text={t("common:buttons.details")} />
    ];
  };

  const twInputProps = (input: InputItem) => {
    return(
      {
        name: input.name,
        type: input.type,
        required: true,
        onChange: input.onChange,
        className: `${input.styling} border-[1px]`,
        placeholder: input.placeholder,
        errorMessage: input.errorMessage,
        labelBefore: "dark:text-gray-200"
      }
    );
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>, data: any) => {
    e.preventDefault();
    if(data.button.onClick) {
      data.button.onClick();
    }
  };

  // Table headers and sort fields for TwTableCardList
  const columnHeaderValues = getColumnHeaderValues(getColumns, intl, t, handleModalOpen);

  return (
    <div className={"mb-[8rem] mx-4 md:mx-0"}>
      <div className="mt-6">
        <FinanceCard
          isLoading={blockchainData.loading}
          className={"dark={shadow-md dark={shadow-gray-900"}
          cardTitle={t("admin.mtcard.title").toString()}
          amount={blockchainData.admin.totalSupply}
          tooltipText={t("admin.mtcard.tooltip").toString()}
        />
      </div>

      {/* Toggle CLRs Table visibility */}
      <VisibilityBarToggle
        showContent={showCLRTable}
        setShowContent={setShowCLRTable}
        title="Credit Limit Requests"
      />

      {/* Credit Limit Requests */}
      {
        showCLRTable &&
          <div
            className={"mf-admin-clrtable-container"}
          >
            {/* MODALS */}
            {
              showModal === AdminPageModals.CLRDetails &&
                <CreditLimitAdmin
                  setShowModal={setShowModal}
                  creditLimiRequest={currentRequest as CreditLimitRequest}
                />
            }

            <Pagination<CreditLimitRequestEdge, GetPaginatedCreditLimitRequestsQuery, GetPaginatedCreditLimitRequestsQueryVariables>
              ref={paginationRef} props={{queryHook: useGetPaginatedCreditLimitRequestsQuery, paginationName, className: "mx-4 mb-4"}}
            >
              {(dataSlice, loading, displayAmount, subscribeToMore) => {
                {
                  // Populate global variable with subscribeToMore function
                  G_subscribeToMore=subscribeToMore;
                }
                return(
                  <div className="mx-4 mt-4">
                    {
                      mdBreakpoint ?
                        <DataTable
                          loading={loading}
                          displayAmount={displayAmount as number}
                          data={dataSlice.map(edge => edge.node) ?? []}
                          columns={getColumns(intl, t, handleModalOpen)}
                        />
                        :
                        <TwTableCardList
                          dataArray={dataSlice.map(edge => edge.node)}
                          displayAmount={displayAmount as number}
                          actionButtons={getActionButtons}
                          defaultSortField="createdAt"
                          tableHeaders={columnHeaderValues}
                          dataType="CreditLimitRequest"
                          dataLoading={loading}
                        />
                    }
                  </div>
                );
              }}
            </Pagination>

          </div>
      }

      {/* Toggle smart contract functions visibility */}
      <VisibilityBarToggle
        showContent={showSmartContractFunctions}
        setShowContent={setShowSmartContractFunctions}
        title="Smart Contract functions"
      />

      {/* Smart Contract functions container */}
      {
        showSmartContractFunctions &&
          <div className="mf-smart-contract-container">
            {
              smartContractsData.map((data) => {
                return (
                  <TwCard key={`scData-${data.title}`} className="p-4 dark:bg-mfdarklight dark:shadow-gray-950 mf-text-primary">
                    <form onSubmit={(e)=> handleOnSubmit(e, data)}>
                      <div className="flex justify-between">
                        <p className="text-lg font-semibold mb-2 mf-text-primary">
                          {data.title}
                        </p>
                        <p>
                          EuroMyronToken
                        </p>
                      </div>
                      {
                        data.inputFields.map((input)=> {
                          return (
                            <TwInput
                              key={`input-${input.name}`}
                              {...twInputProps(input)}
                            />
                          );
                        })
                      }
                      <button
                        onClick={data.button.onClick}
                        className={`${data.button.styling} mt-2 dark:shadow-gray-950 shadow-md`}
                      >
                        {data.button.text}
                      </button>
                      <div className="flex mt-2 border-[1px] border-gray-200 rounded-full p-2">
                        <p className="font-light mr-2 mf-text-primary">{t("admin.output.label")}</p>
                        <p>{data.output}</p>
                      </div>
                    </form>
                  </TwCard>
                );
              })
            }
          </div>
      }
    </div>
  );
}

export default Admin;
