import { ApolloClient, from, makeVar, split } from "@apollo/client";
import { SessionStorageWrapper, persistCache } from "apollo3-cache-persist";
import { getMainDefinition } from "@apollo/client/utilities";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { onError } from "@apollo/client/link/error";
import { typeDefs } from "@/cache/TypeDefs";
import { cache } from "./cache/CacheConfig";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { toast } from "react-toastify";
import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";

if (import.meta.env.VITE_APP_ENV === "dev") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export const isDarkModeVar = makeVar(localStorage.getItem("darkMode"));
export const spHasNewNotifications = makeVar(sessionStorage.getItem("newNotifications"));
export const isConnectedVar = makeVar(sessionStorage.getItem("isConnected"));

// Array of error messages that should not be displayed to the user with the toast
const errorsToNotDisplay = [
  "Wrong credentials!",
  "Invalid email address!",
  "No credentials or missing field!",
];

// await before instantiating ApolloClient, else queries might run before the cache is persisted
// link cache to Session Storage for persistence
persistCache({
  cache,
  storage: new SessionStorageWrapper(window.sessionStorage)
});

const uploadLink = createUploadLink({
  uri: `${import.meta.env.VITE_BACKEND_URL}`,
  credentials: "include",
  headers: {
    "Apollo-Require-Preflight": "true",
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${import.meta.env.VITE_WS_URL}`,
    keepAlive: 10_000, // ping server every 10 seconds
    connectionParams: async () => ({
      reconnect: true,
      credentials: "include",
      Authorization: sessionStorage.getItem("token") || ""
    })
  }),
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  toast.dismiss();
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      //*** For debugging purposes, uncomment to see error messages
      if (!errorsToNotDisplay.includes(message)) {
        toast.error(message, { toastId: "gqlError" });
      }
      console.log(
        JSON.stringify(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`,
        )
      );
    });

  }
  if (networkError){
  // *** Uncomment to console.log() the error instead of showing it in a toast
    console.log(`[Network error]: ${networkError}`);
    // toast.error(`[Network Error]:\n ${networkError}`, {toastId: "gqlNetworkError"});
  }
});


// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  uploadLink,
);

// The `from` function combines an array of individual links
// into a link chain
const link = from([errorLink, splitLink]);
// Create Apollo Client from authorisation link and cache
export const client = new ApolloClient({
  connectToDevTools: true,
  link,
  cache,
  typeDefs
});
