
import * as Apollo from "@apollo/client";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { PaginationWrapperProps, UseGeneratedQueryOptions } from "@/types/pagination";
import React, { ReactNode, forwardRef, useImperativeHandle } from "react";
import { TwSelect, TwSelectOption } from "./selector";
import { Oval } from "react-loader-spinner";
import { usePaginatedQuery } from "@/hooks/paginatedQuery";
import { useTranslation } from "react-i18next";

/**
 * Props for pagination layout support components.
 */
type Props = {
  children?: React.ReactNode | React.ReactNode[];
};

/**
 * Component to support pagination layout. Use this with `PaginationContentScrollable` nested inside
 * when additional non-scrollable components are included within the Pagination component
 * (e.g., filter components).
 *
 * @param {React.ReactNode | React.ReactNode[]} children - React node to be wrapped in.
 * @returns {JSX.Element} The wrapper component for pagination content.
 *
 * @example
 * ```javascript
 * <PaginationContentWrapper>
 *   <FilterComponent />
 *   <PaginationContentScrollable>
 *     <TableComponent />
 *   </PaginationContentScrollable>
 * </PaginationContentWrapper>
 * ```
 */
function PaginationContentWrapper({ children }: Props): JSX.Element {
  return (
    <div className="mf-flex-y-fill space-y-4">
      {children}
    </div>
  );
}

/**
 * Component to support pagination layout, where content within this component will be scrollable.
 *
 * @param {React.ReactNode | React.ReactNode[]} children - React node to be wrapped in.
 * @param {React.Ref<HTMLDivElement>} ref - A ref to the scrollable div element.
 * @returns {JSX.Element} The scrollable content wrapper.
 *
 * @example
 * ```javascript
 * <PaginationContentScrollable ref={scrollableRef}>
 *   <TableComponent />
 * </PaginationContentScrollable>
 * ```
 */
const PaginationContentScrollable = forwardRef<HTMLDivElement, Props>(({ children }, ref) => {
  return (
    <div ref={ref} className="mf-flex-y-fill overflow-x-hidden overflow-y-auto space-y-4 h-[300px]">
      {children}
    </div>
  );
});
PaginationContentScrollable.displayName = "PaginationContentScrollable";

/**
 * This component holds all UI for Pagination. It calls the pagination hook and passes the `dataSlice` to the child component.
 *
 * @template TEdge - The type of each item in the data slice.
 * @template TData - The type of the query data.
 * @template TVariables - The type of the query variables.
 * @param {PaginationWrapperProps<TEdge, TData, TVariables>} props - Props including query hook, pagination name, and more.
 * @param {React.Ref} ref - Ref to trigger `handleFilterSubmit` from the parent component.
 * @returns {JSX.Element} The Pagination component.
 *
 * @example
 * ```javascript
 * const paginationRef = useRef();
 *
 * <Pagination
 *   ref={paginationRef}
 *   props={{
 *     queryHook: useGetItemsQuery,
 *     paginationName: "itemsPagination",
 *   }}
 * >
 *   {(dataSlice, loading, displayAmount, subscribeToMore) => (
 *     <TableComponent data={dataSlice} loading={loading} />
 *   )}
 * </Pagination>
 * ```
 */
function InnerPagination<TEdge, TData, TVariables extends Apollo.OperationVariables>(
  { props, children }: PaginationWrapperProps<TEdge, TData, TVariables>,
  ref: React.Ref<{
    triggerHandleFilterSubmit: (_newVariables: Partial<TVariables>) => void,
    getData?: () => TEdge[],
    getLoading?: () => boolean,
    getSubscribeToMore?: any; // SubscribeToMoreFn<TData, TVariables>
  }>
): JSX.Element {

  const { queryHook, paginationName, reversePagination, className } = props;
  const options: UseGeneratedQueryOptions<TData, TVariables> = { queryHook };

  const {
    loading,
    dataSlice,
    handleNextPage,
    subscribeToMore,
    handlePreviousPage,
    handleDisplayAmount,
    handleFilterSubmit,
    loadingMoreData,
    displayAmount,
    showingFrom,
    showingTo,
    rowsFetched,
  } = usePaginatedQuery<TEdge, TData, TVariables>(options, paginationName, reversePagination);

  // Hooks
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    triggerHandleFilterSubmit(newVariables: Partial<TVariables>) {
      handleFilterSubmit(newVariables);
    },
    getData() {
      return dataSlice; // Ensure that this returns the current slice of data
    },
    getLoading() {
      return loading || loadingMoreData;
    },
    getSubscribeToMore: subscribeToMore,
  }));

  return (
    <div className="mf-flex-y-fill w-full">
      {/* Table goes here */}
      <div className="mf-flex-y-fill overflow-y-hidden">
        {children(dataSlice, loading || loadingMoreData, displayAmount, subscribeToMore) as ReactNode}
      </div>

      {/* Pagination buttons */}
      <div className={`${className} select-none flex items-center pt-2`}>
        <button
          onClick={handlePreviousPage}
          className="bg-white dark:bg-mfdarklight border-[1px] border-gray-600 rounded-md p-1 mr-2 active:scale-95 dark:border-gray-300 dark:text-gray-300"
        >
          <ChevronLeftIcon className="w-6 h-6" />
        </button>

        <button
          disabled={loading || loadingMoreData}
          onClick={handleNextPage}
          className={!loadingMoreData ? "bg-white dark:bg-mfdarklight border-[1px] border-gray-600 rounded-md p-1 mr-2 active:scale-95 dark:border-gray-300 dark:text-gray-300"
            : "bg-white dark:bg-mfdarklight border-[1px] border-gray-300 rounded-md p-1 mr-2 active:scale-95 dark:border-gray-600 dark:text-gray-400"}
        >
          {
            loadingMoreData ?
              <Oval
                height={24}
                width={24}
                color="#EE5300"
                wrapperStyle={{}}
                wrapperClass=""
                visible
                ariaLabel='oval-loading'
                secondaryColor="#EE5300"
                strokeWidth={8}
                strokeWidthSecondary={8}
              />:
              <ChevronRightIcon className="w-6 h-6" />
          }
        </button>

        <div className="flex items-center space-x-2">
          {/* Display Amount Toggle */}

          <TwSelect
            name={"displayAmount"}
            key={"displayAmount"}
            className="select-none cursor-pointer dark:bg-mfdarklight dark:placeholder:text-gray-200 outline-none border-[1px] border-gray-600 max-w-[70px] rounded-md p-1 h-[34px] dark:border-gray-300 dark:text-gray-300"
            defaultValue={displayAmount?.toString()}
            onChange={(event) => {
              handleDisplayAmount(parseInt(event?.value ?? ""));
            }}
            required
          >
            {
              [5, 10, 25, 50].map((displayAmount) => {
                return (
                  <TwSelectOption
                    key={`displayAmount-option-${displayAmount}`}
                    value={displayAmount.toString()}
                    text={displayAmount.toString()}
                  />
                );
              })}
          </TwSelect>

          <div className="hidden md:flex flex-col">
            <p className="text-gray-600 dark:text-gray-300 text-sm">
              {// Assets fetched: **max amount**
                `Fetched ${rowsFetched} rows`
              }
            </p>

            <p className="text-gray-600 dark:text-gray-300 text-sm">
              {// Showing assets: **start** to **end**
                `Showing: ${showingFrom} ${t("common:text.to")} ${showingTo}`
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

// Ref forwarding is used to pass the ref from the parent component to the child component
export const Pagination = forwardRef(InnerPagination) as <TEdge, TData, TVariables extends Apollo.OperationVariables>(
  _p: PaginationWrapperProps<TEdge, TData, TVariables> &
  React.RefAttributes<{ triggerHandleFilterSubmit:
    (_newVariables: Partial<TVariables>) => void,
    getData?: () => TEdge[],
    getLoading?: () => boolean,
    getSubscribeToMore?: any; // SubscribeToMoreFn<TData, TVariables>
  }>,
) => React.ReactElement;

export { PaginationContentScrollable, PaginationContentWrapper };
