import myronLogo from "@/assets/MYRON-icon-transparent.webp";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Bars3Icon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Company, Developer, Employee,useCreditLimitRequestCreatedSubscription, useCreditLimitRequestEditedSubscription, UserType } from "@/graphql/__generated__/graphql-operations";
import { spHasNewNotifications } from "@/client";
import { useBlockchainData } from "@/hooks/cache/useBlockchainData";
import { useCompanyData } from "@/hooks/cache/useCompanyData";
import { ToastIcon } from "react-toastify/dist/types";
import { SetWalletPublicKeyModal, SetWalletSetupModal, SetWalletWelcomeModal } from "./modals";
import Navlinks from "./Navlinks";
import Wallet from "./Wallet";
import Profile from "./Profile";
import Transaction from "./Transaction";
import { useServiceProviderData, useUserData } from "@/hooks";
import { SetWalletSetupModals } from "@/constants/enums";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { useTheme } from "@/context/ThemeProvider";

type Props = {
  className?: string;
}

export const EURM_ADDRESS = import.meta.env.VITE_EURO_MYRON_ADDRESS;

/**
 * Header component for the application.
 *
 * Displays the logo, navigation links, user profile, and wallet connection options.
 * Handles various side effects including:
 * - Subscriptions to credit limit request updates
 * - Showing appropriate modals based on wallet setup
 * - Navigating to payment pages if required based on blockchain data
 * - Showing notifications for credit limit requests
 *
 * @param {Props} props - The props for the component
 * @param {string} [props.className] - Additional class names for styling
 * @param {boolean} props.darkMode - Current dark mode state
 * @param {Dispatch<SetStateAction<boolean>>} props.setDarkMode - Function to update dark mode state
 *
 * @returns {JSX.Element} The rendered Header component
 *
 * @example
 * ```javascript
 * import Header from "@/components/Header";
 *
 * function App() {
 *   const [darkMode, setDarkMode] = useState(false);
 *
 *   return (
 *     <Header
 *       darkMode={darkMode}
 *       setDarkMode={setDarkMode}
 *     />
 *   );
 * }
 * ```
 */
const Header = ({className }: Props): JSX.Element => {

  // Hooks
  const path = useLocation();
  const navigate = useNavigate();
  const { darkMode, setDarkMode } = useTheme();

  // Query hooks
  const spdetails = useServiceProviderData();
  const company = useCompanyData();
  const user = useUserData();
  const blockchainData = useBlockchainData();

  if(!blockchainData) {
    return (
      <div>
        NO BLOCKCHAIN DATA
      </div>
    );
  }

  if(!user) {
    return (
      <p>We are currently loading....</p>
    );
  }

  const [sideMenuOpen, setSideMenuOpen] = useState(false);
  const [showSetupModal, setShowSeupModal] = useState<SetWalletSetupModals>(SetWalletSetupModals.NoModal);

  /**
   * Subscriptions for CLRs
   */
  const {
    data: clrEditedData,
    loading: clrEditedLoading
  } = useCreditLimitRequestEditedSubscription({
    variables: {
      serviceProviderId: company && company._id
    }
  });

  // ADMIN Only
  const {
    data: clrCreatedData = [],
    loading: clrCreatedLoading = false
  } = user.usertype === UserType.Developer ? useCreditLimitRequestCreatedSubscription({
    variables: {
      serviceProviderId: company && company._id
    },
  }): {};

  // Listener for SP --> Checks if Interest Payment is due
  useEffect(() => {
    if(path.pathname.includes("/transactions")) return;
    if(user.__typename !== "Developer") {
      if(blockchainData.initialized && !blockchainData.loading) {
        const {
          serviceProvider: {
            interestPaymentDue
          },
          inventoryProvider: {
            isEndPaymentDue
          }
        } = blockchainData;
        if(isEndPaymentDue || interestPaymentDue) {
          navigate("/user/makepayment");
        }
      }
    }
    // Runs on every page change and every update of the blockchainData cache
  }, [blockchainData, path.pathname]);

  // Toast handler
  // Listen for edited CLRs of current SP
  useEffect(() => {
    if(!clrEditedLoading && clrEditedData) {
      const requestSPID = clrEditedData.creditLimitRequestEdited?.serviceproviderID;
      if(company && requestSPID === company._id) {
        toast.success("A credit limit request you created has been edited.", {
          icon: "🛎️" as unknown as ToastIcon,
        });
        sessionStorage.setItem("newNotifications", "true");
        spHasNewNotifications("true");
      }
    }
  }, [clrEditedData, clrEditedLoading]);

  // Toast handler
  // Listen for created CLRs for Admin
  useEffect(() => {
    if(user.__typename === "Developer") {
      if(!clrCreatedLoading && clrCreatedData) {
        toast.success("A new credit limit request has been created.", {
          icon: "🛎️" as unknown as ToastIcon,
        });
      }
    }
  }, [clrCreatedData, clrCreatedLoading]);

  /**
   * Listener for company key changes, open wallet setup modal if public key is not set
   */
  useEffect(() => {
    if (company && company.publickey === "0x0") {
      setShowSeupModal(SetWalletSetupModals.Welcome);
    }
  }, [company?.publickey]);

  return (
    <div
      className={`${className} mf-header-main-container px-4 md:px-5`}
    >
      {/* Top Container --> MetaMask */}
      <div className="flex w-full justify-between items-center mt-1 mb-1 py-1">
        {/* Logo */}
        <Link className="" to={user.__typename === "Developer" ? "/admin/dashboard":"/user/dashboard"}>
          <LazyLoadImage
            alt={"Myron logo"}
            className="mf-header-logo"
            src={myronLogo}
            effect="opacity"
          />
        </Link>

        <div className="flex items-center space-x-1">
          {/* Connect to Wallet */}
          <Wallet blockchainData={blockchainData} darkMode={darkMode} />
        </div>
      </div>
      {/* Bottom Container --> Logo; NavLinks; Profile; Dark Mode */}
      <div className="mf-header-inner-top-container">
        {/* Navbar/Side Menu Links */}
        <Navlinks
          user={user as (Employee | Developer)}
          path={path}
          spdetails={spdetails}
          company={company as Company}
          sideMenuOpen={sideMenuOpen}
          setSideMenuOpen={setSideMenuOpen}
        />

        {/* Right Nav Container */}
        <div
          className="flex gap-2 w-full items-center justify-end"
        >
          {/* EURM Shortcut */}
          {
            user.role === "SUPERUSER" &&
            <Transaction company={company} />
          }

          {/* Profile & Dropdown Menu */}
          <Profile
            user={user}
            company={company}
            darkMode={darkMode}
            setDarkMode={setDarkMode}
          />

          {/* Side Menu Icon */}
          <div
            onClick={() => setSideMenuOpen((prev) => !prev)}
            className="mf-navbar-sidemenu-icons"
          >
            {
              sideMenuOpen ?
                <XMarkIcon className="md:hidden w-8 h-8 dark:text-mforange" />:
                <Bars3Icon className="md:hidden w-8 h-8 dark:text-mforange" />
            }
          </div>
        </div>
      </div>

      {/* Modals */}
      {/* Initial public key setup modals */}
      {
        showSetupModal === SetWalletSetupModals.Welcome &&
        <SetWalletWelcomeModal
          setShowModal={setShowSeupModal}
          username={user.firstname}
        />
      }

      {
        showSetupModal === SetWalletSetupModals.Setup &&
        <SetWalletSetupModal
          setShowModal={setShowSeupModal}
        />
      }

      {
        showSetupModal === SetWalletSetupModals.PublicKey &&
        <SetWalletPublicKeyModal
          setShowModal={setShowSeupModal}
          company={company}
        />
      }
    </div>
  );
};
Header.displayName = "Header";

export default Header;
