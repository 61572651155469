import { useWeb3 } from "@/context/Web3Context";
import { MinusCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatNumberLocale } from "@/utils/helpers";
import { useIntl } from "react-intl";
import { TfiWallet } from "react-icons/tfi";
import { useTranslation } from "react-i18next";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/Popover";
import useMdBreakpoint from "@/hooks/useMdBreakpoint";
import { ValueSkeleton } from "../skeletons";

type Props = {
  blockchainData: any;
  darkMode: boolean;
}

function Wallet({ blockchainData, darkMode }: Props) {

  const [showPopover, setShowPopover] = useState(false);
  const [hasClickedConnect, setHasClickedConnect] = useState(false);
  const mdBreakpoint = useMdBreakpoint();

  const {
    connectWallet,
    disconnectWallet,
    isConnected,
    showUI,
    disabled,
    handleWalletOnPageLoad
    // isThirdPartyWallet
  } = useWeb3();

  /**
   * Prevents the wallet popover from opening when the user connects
   */
  useEffect(() => {
    if (isConnected && hasClickedConnect) {
      setShowPopover(true);
      setHasClickedConnect(false);
    } else if (isConnected && !hasClickedConnect) {
      setShowPopover(false); // Close if connected but no click
    }
  }, [isConnected, hasClickedConnect]);

  // Hooks
  const intl = useIntl();
  const { t } = useTranslation(["components"]);

  const backgroundColour = darkMode ? "bg-mfdarklighter text-white border-transparent shadow-gray-900": "bg-white";
  const hoverColour = darkMode ? "hover:bg-white/20": "hover:bg-gray-100";

  useEffect(() => {
    handleWalletOnPageLoad();
  }, []);

  const walletPopoverLinks: any[] = [
    {
      name: t("header.wallet.button.viewwallet"),
      icon: <UserIcon className="w-6 h-6 dark:text-white" />,
      onClick: () => {
        showUI();
      }
    },
    {
      name: t("header.wallet.button.disconnect"),
      icon: <MinusCircleIcon className="w-6 h-6 dark:text-white" />,
      onClick: () => {
        disconnectWallet();
        toast.warning(t("header.wallet.disconnected"), {toastId: "walletDisconnect"});
      }
    }
  ];

  return (
    <div className="flex items-center gap-x-1">
      <div className="mx-1 items-center flex text-xs md:text-lg mf-text-primary">
        {
          blockchainData.loading ?
            <ValueSkeleton className="min-w-[100px] max-h-[20px]" />:
            formatNumberLocale(intl, blockchainData.balance, "decimal", false, false, true)
        }
      </div>
      <Popover open={showPopover} onOpenChange={setShowPopover}>
        <PopoverTrigger
          id="metaMaskContainer"
          disabled={disabled}
          className="has-tooltip mf-navbar-metamask-container group px-2 dark:bg-mfdarklighter"
          onClick={isConnected ? () => setShowPopover(true) : () => {setHasClickedConnect(true); connectWallet();}}
        >
          {
            isConnected ?
              <span className="text-gray-600 dark:text-white">{t("header.wallet.button.connected")}</span>
              :
              <span className="text-mforange dark:text-white">{mdBreakpoint ? t("header.wallet.button.connect") : t("common:buttons.connect")}</span>
          }
          <TfiWallet className={`ml-2 w-6 h-6 m-1 dark:text-white ${isConnected ? "text-gray-600" : "text-mforange"}`} />
        </PopoverTrigger>
        {
          isConnected && showPopover &&
          <PopoverContent
            align="end"
            className={`rounded-md flex flex-col max-w-[250px] font-Montserrat ${backgroundColour}`}
          >
            {
              walletPopoverLinks.map(({name, icon, onClick}) => (
                <button
                  onClick={onClick}
                  key={`wallet-popover-${name}`}
                  className={`flex items-center gap-2 py-1 px-2 rounded-md ${hoverColour}`}
                >
                  <div className="">{icon}</div>
                  <div className="">{name}</div>
                </button>
              ))
            }
          </PopoverContent>
        }
      </Popover>
    </div>
  );
}

export default Wallet;
